:root {
  --white: #fff;
  --light-gray: #f7f7f7;
  --dark-gray: #dadada;
  --black: black;
}

.nav-pills .nav-link {
  color: var(--black);
  border-color: var(--white);
  border-style: solid;
  border-width: 1px;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus{
  color: var(--black);
  background-color: var(--light-gray);
  border-color: var(--dark-gray);
  border-style: solid;
  border-width: 1px;
}
